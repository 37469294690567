import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from 'components/TableWithPagination/desktop/Table/Table';

import './SportBets.scss';

const b = block('sport-bets');

const SportBets = () => {
    const { actionProcessing } = useSelector(state => state.reports, shallowEqual);
    const betsHistory = useSelector(state => state.reports.betsHistory, shallowEqual);

    const [count, setCount] = useState(10);
    const [page, setPage] = useState(0);

    const onItemsOnPageChanged = newItemsOnPage => {
        setCount(newItemsOnPage);
        setPage(0);
    };

    return (
        <div className={b()}>
            <Spinner isLoading={actionProcessing} />
            <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
            <Table
                currentPage={page}
                onPageClick={setPage}
                onItemsOnPageChanged={onItemsOnPageChanged}
                thTdItems={betsHistory.data}
                totalCount={betsHistory.count}
            />
        </div>
    );
};

export default SportBets;
