import React, { useMemo, useCallback, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { languagesWithIcons } from 'services/locale';

import { actions } from 'features/locale';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow.svg';
import arrow2SVG from './img/arrow2.svg';

import './LanguageDropdownSelector.scss';

const b = block('language-dropdown-selector-desktop');

const LanguageDropdownSelector = ({ isHeader = false, isMobile = false}) => {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.locale.lang, shallowEqual);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const changeLang = useCallback(
    newLang => {
      dispatch(actions.changeLang(newLang));
    },
    [dispatch],
  );

  const [isOpen, changeOpen] = useState(false);

  const items = useMemo(
    () =>
      Object.values(languagesWithIcons).map(item => {
        const isSelected = lang === item.lang;
        const isHeaderMobile = isHeader && isMobile

        return (
          <div className={b('item', { selected: isSelected, isHeaderMobile  })} onClick={() => changeLang(item.lang)} key={item.lang}>
            <img className={b('item-img')} src={item.icon} alt={item.text} />
            {!isHeaderMobile && <span className={b('item-text')}>{isHeader ? item.lang.slice(0, 2).toUpperCase() : item.text}</span>}
          </div>
        );
      }),
    [changeLang, lang, isHeader],
  );

  return (
    <div className={b({ open: isOpen, isHeader })} onClick={() => changeOpen(!isOpen)}>
      {!isHeader && <div className={b('title')}>{locale.language}</div>}
      <div className={b('current', { isMobile, isHeader })}>
        <div className={b('wrapper', { isMobile })}>
          <img className={b('item-img')} src={languagesWithIcons[lang].icon} alt={languagesWithIcons[lang].text} />
          {!isHeader && <span className={b('current-name')}>{languagesWithIcons[lang]?.text}</span>}
        </div>
        {!isMobile && <SVG className={b('current-item-arrow')} svgProps={{ svg: isHeader ? arrow2SVG : arrowSVG }} />}
      </div>
      {isOpen ? <div className={b('items', { isMobile })}>{items}</div> : null}
    </div>
  );
};

export default LanguageDropdownSelector;
