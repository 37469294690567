import React from 'react';
import block from 'bem-cn';
import './TableHead.scss';

const TableHead = ({ locale, type }) => {
  const b = block('table-head-cash');

  if (type === 'my-debt-account') {
    return (
      <div className={b()}>
        <div className={b('table-head')}>{locale.id}</div>
        <div className={b('table-head')}>{locale.date}</div>
        <div className={b('table-head')}>{locale.fromUser}</div>
        <div className={b('table-head')}>{locale.toUser}</div>
        <div className={b('table-head')}>{locale.transactionType}</div>
        <div className={b('table-head')}>{locale.amount}</div>
        <div className={b('table-head')}>{locale.balanceBefore}</div>
        <div className={b('table-head')}>{locale.balanceAfter}</div>
      </div>
    );
  }

  return (
    <div className={b()}>
      <div className={b('table-head')}>{locale.date}</div>
      <div className={b('table-head')}>{locale.transactionType}</div>
      <div className={b('table-head')}>{locale.amount}</div>
      <div className={b('table-head')}>{locale.balanceBefore}</div>
      <div className={b('table-head')}>{locale.balanceAfter}</div>
    </div>
  );
};

export default TableHead;
