import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import SVG from 'components/SVG';
import GeneratePasswordButton from 'components/GeneratePasswordButton';
import { passwordSettingsConfig } from 'shared/config';

import { actions } from 'features/users/redux';

import { userTypes } from '../../../../data';

import './ChangePassword.scss';

const b = block('change-password-users');

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { id: agentId } = useSelector(state => state.auth);
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale);
  const [password, setPassword] = useState('');
  const { userInfo } = useSelector(state => state.users);

  const { username, role } = userInfo;

  const isAgent = useMemo(() => +userId === agentId, [agentId, userId]);

  useEffect(() => {
    if (!isAgent) dispatch(actions.getUserInfo({ id: userId }));
  }, [dispatch, isAgent, userId]);

  const onSubmit = useCallback(() => {
    dispatch(actions.changePassword({ data: { password }, id: userId }));
  }, [password, dispatch]);

  const changeValue = useCallback(e => {
    setPassword(e.currentTarget.value);
  }, []);

  const onGeneratePassword = useCallback(password => {
    setPassword(password);
  }, []);

  return (
    <div className={b()}>
      <div className={b('form')}>
        <div className={b('header')}>{locale[isAgent ? 'changeMyPassword' : 'changePassword']}</div>
        <div className={b('top')}>
          {!isAgent && (
            <div className={b('user-block')}>
              <div className={b('user-block-left')}>
                <SVG svgProps={{ svg: userTypes[role]?.icon }} className={b('user-block-icon')} />
                <div className={b('user-block-info')}>
                  <span>{username}</span>
                  <div className={b('user-block-info-type', { role })}>{userTypes[role]?.type}</div>
                </div>
              </div>
              <div className={b('user-block-right')}>
                <span>ID:</span>
                <span>{userId}</span>
              </div>
            </div>
          )}

          <div className={b('fields')}>
            <div className={b('form', 'input-block')}>
              <div className={b('input')}>
                <GeneratePasswordButton
                  callback={onGeneratePassword}
                  passwordSettings={passwordSettingsConfig.player}
                />
              </div>
            </div>
            <div className={b('form', 'input-block')}>
              <span className={b('form', 'input-block-title')}>{locale.newPassword}</span>
              <div className={b('input')}>
                <Input
                  placeholder={locale.password}
                  onChange={changeValue}
                  size="high"
                  type="text"
                  value={password}
                  name="password"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button type="submit" callBack={onSubmit} size="low" text={locale.changePassword} />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
