import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import DaysFilter from 'features/reports/view/desktop/DaysFilter';
import CheckBox from 'components/CheckBox';

import { actions } from 'features/finance';

import './FilterBlock.scss';

const b = block('filter-block-my-debt');

const FilterBlock = ({ count, currentPage, isMobile, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: dayjs().subtract(1, 'week').startOf('day').toDate(),
    endDate: dayjs().startOf('day').toDate(),
  });
  const [activeDay, setActiveDay] = useState('');
  const [state, setState] = useState({
    isOnlyPersonalHigherTransfers: false,
    isOnlyUserTransfers: false,
    isDeposits: false,
    isWithdrawals: false,
  });

  const onChangeOnlyPersonalHigherTransfers = () => {
    setState(prev => ({
      ...prev,
      isOnlyPersonalHigherTransfers: !prev.isOnlyPersonalHigherTransfers,
    }));
  };

  const onChangeOnlyUserTransfers = () => {
    setState(prev => ({
      ...prev,
      isOnlyUserTransfers: !prev.isOnlyUserTransfers,
    }));
  };

  const onChangeDeposits = () => {
    setState(prev => ({
      ...prev,
      isDeposits: !prev.isDeposits,
    }));
  };

  const onChangeWithdrawals = () => {
    setState(prev => ({
      ...prev,
      isWithdrawals: !prev.isWithdrawals,
    }));
  };

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const onClickSearch = () => {
    onPageClick(0);
    handleSearch();
  };

  const handleSearch = useCallback(() => {
    const requestedData = {
      checkboxes: state,
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getFinanceHistory(requestedData));
  }, [state, count, currentPage, date, dispatch]);

  const onChangeActiveDay = useCallback(
    dayFilter => {
      setActiveDay(dayFilter);
      setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
    },
    [date],
  );

  useEffect(() => {
    handleSearch();
  }, [count, currentPage, handleSearch]);

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('wrapper')}>
        <div className={b('dates')}>
          <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
          <div className={b('or')}>or</div>
          <div className={b('date')}>
            <div className={b('date-row')}>
              <div className={b('title')}>{locale.dateFrom}</div>
              <div className={b('date-input')}>
                <InputDate showTimeSelect selected={date.beginDate} onChange={onChangeBeginDate} />
              </div>
            </div>
            <div className={b('date-row')}>
              <div className={b('title')}>{locale.dateTo}</div>
              <div className={b('date-input')}>
                <InputDate showTimeSelect selected={date.endDate} onChange={onChangeEndDate} />
              </div>
            </div>
          </div>
        </div>
        <div className={b('checkboxes')}>
          <div className={b('checkbox')}>
            <CheckBox
              checked={state.isOnlyPersonalHigherTransfers}
              onChange={onChangeOnlyPersonalHigherTransfers}
              name="isOnlyPersonalHigherTransfers"
            />
            <p className={b('checkbox-title')}>{locale.onlyPersonalHigherTransfers}</p>
          </div>
          <div className={b('checkbox')}>
            <CheckBox
              checked={state.isOnlyUserTransfers}
              onChange={onChangeOnlyUserTransfers}
              name="isOnlyUserTransfers"
            />
            <p className={b('checkbox-title')}>{locale.onlyUserTransfers}</p>
          </div>
          <div className={b('checkbox')}>
            <CheckBox checked={state.isDeposits} onChange={onChangeDeposits} name="isDeposits" />
            <p className={b('checkbox-title')}>{locale.deposits}</p>
          </div>
          <div className={b('checkbox')}>
            <CheckBox checked={state.isWithdrawals} onChange={onChangeWithdrawals} name="isWithdrawals" />
            <p className={b('checkbox-title')}>{locale.withdrawals}</p>
          </div>
          <div className={b('button')}>
            {/* <div className={b('title')} style={{ color: 'transparent' }}>
            transparent placeholder
          </div> */}
            <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
