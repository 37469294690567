import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import block from 'bem-cn';
import dayjs from 'dayjs';

import Spinner from 'components/Spinner/view/desktop';
import Modal from 'components/ModalProvider/Modal/Modal';
import { actions } from 'features/reports/redux';
import { userTypes } from 'features/users/data';

import FilterBlock from './FilterBlock';
import Table from './Table/Table';

import './DetailByPlayer.scss';

const b = block('detail-by-player');

const DetailByPlayer = ({ isMobile }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const statisticPlayer = useSelector(state => state.reports.statisticPlayer, shallowEqual);
  // const statisticAgentPlayer = useSelector(state => state.reports.statisticAgentPlayer, shallowEqual);
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  // const [modalActive, setModalActive] = useState(false);
  // const [activeTab, setActiveTab] = useState('all');
  const [date, setDate] = useState({
    beginDate: dayjs().subtract(1, 'day').startOf('day').toDate(),
    endDate: dayjs().startOf('day').toDate(),
  });

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  // const handleModal = (id, agentName) => {
  //   if (!modalActive) {
  //     const requestedData = {
  //       user_id: id,
  //       agentName,
  //       isDirectOnly: false,
  //       ...date
  //     };
  //     dispatch(actions.getUsersForAgent(requestedData));
  //   } else {
  //     const requestedData = {
  //       isDirectOnly: false,
  //       ...date
  //     };
  //     dispatch(actions.getStatisticPlayer(requestedData));
  //   }
  //   setModalActive(prev => !prev);
  // };

  // const handleModalClose = () => {
  //   setModalActive(false);
  // };

  // const handleUserSelection = (id, agentName) => {
  //   const requestedData = {
  //     user_id: id,
  //     agentName,
  //     isDirectOnly: false,
  //     ...date
  //   };
  //   dispatch(actions.getUsersForAgent(requestedData));
  // };

  // const handleTabChange = tab => {
  //   setActiveTab(tab);
  // };

  // const filteredUserStatistic = statisticAgentPlayer?.userStatistic?.filter(user => {
  //   if (activeTab === 'all') return true;
  //   return user.role === activeTab;
  // }) ?? [];

  return (
    <div className={b({ isMobile })}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock
        count={count}
        currentPage={page}
        onPageClick={setPage}
        setDate={setDate}
        date={date}
        />
      <Table
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        thTdItems={statisticPlayer?.userStatistic}
        totalCount={statisticPlayer?.details?.totalCount}
        // arrowOnClick={handleModal}
      />
      {/* {modalActive && (
        <Modal
          content={
            <div className={b('modal')}>
              <Spinner isLoading={actionProcessing} />
              <div className={b('modal-header')}>
                {locale.usersBy} {statisticAgentPlayer?.details?.agentName}
              </div>
              <div className={b('tabs-panel')}>
                <div
                  className={b('tab', { active: activeTab === 'all' })}
                  onClick={() => handleTabChange('all')}
                >
                  {locale.all}
                </div>
                {Object.keys(userTypes)?.map(tab => (
                  <div
                    key={tab}
                    className={b('tab', { active: activeTab === tab })}
                    onClick={() => handleTabChange(tab)}
                  >
                    {locale[userTypes[tab]?.type]}
                  </div>
                ))}
              </div>
              <Table
                currentPage={page}
                onPageClick={setPage}
                onItemsOnPageChanged={newItemsOnPage => {
                  setCount(newItemsOnPage);
                  setPage(0);
                }}
                thTdItems={filteredUserStatistic}
                totalCount={statisticAgentPlayer?.details?.totalCount}
                arrowOnClick={handleUserSelection}
                withoutHeader
              />
            </div>
          }
          title=""
          isMobile
          withoutBG
          closeFunction={handleModalClose}
        />
      )} */}
    </div>
  );
};

export default DetailByPlayer;
