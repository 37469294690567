/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

const lowerCaseCharacters = 'abcdefghijklmnopqrstuvwxyz';
const upperCaseCharacters = lowerCaseCharacters.toUpperCase();
const digitsCharacters = '0123456789';
const specialsCharacters = '!-_?#$';

export const generatePassword = settings => {
  const {
    digits: { amount: digitsAmount },
    letters: { lowercase: lowerCaseAmount, uppercase: upperCaseAmount, amount: lettersAmount },
    special_characters: { amount: specialsAmount },
    min_length: minLength,
  } = settings;

  const additionalLettersAmount = lettersAmount - lowerCaseAmount - upperCaseAmount;

  const digits = Array(digitsAmount)
    .fill(0)
    .map(_ => digitsCharacters.charAt(Math.floor(Math.random() * digitsCharacters.length)))
    .join('');

  const lowerCase = Array(lowerCaseAmount)
    .fill(0)
    .map(_ => lowerCaseCharacters.charAt(Math.floor(Math.random() * lowerCaseCharacters.length)))
    .join('');

  const uppercase = Array(upperCaseAmount)
    .fill(0)
    .map(_ => upperCaseCharacters.charAt(Math.floor(Math.random() * upperCaseCharacters.length)))
    .join('');

  const additionalLetters = Array(additionalLettersAmount > 0 ? additionalLettersAmount : 0)
    .fill(0)
    .map(_ => upperCaseCharacters.charAt(Math.floor(Math.random() * upperCaseCharacters.length)))
    .join('');

  const specials = Array(specialsAmount)
    .fill(0)
    .map(_ => specialsCharacters.charAt(Math.floor(Math.random() * specialsCharacters.length)))
    .join('');

  const tempResult = `${uppercase}${digits}${lowerCase}${additionalLetters}${specials}`;
  const additionalSymbolsAmount = minLength - tempResult.length;

  const additionalSymbols = Array(additionalSymbolsAmount > 0 ? additionalSymbolsAmount : 0)
    .fill(0)
    .map(_ => upperCaseCharacters.charAt(Math.floor(Math.random() * upperCaseCharacters.length)))
    .join('');

  const resultArr = `${tempResult}${additionalSymbols}`.split('');

  // shuffle
  resultArr.forEach((v, i, arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    [arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]];
  });

  return resultArr.join('');
};
