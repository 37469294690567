export const list = {
  all: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '1.svg',
    darkBig: '1.svg',
  },

  digitain: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '1.svg',
    darkBig: '1.svg',
  },

  line1x: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '1.svg',
    darkBig: '1.svg',
  },

  // virtual
  goldenrace: {
    lightSmall: '1.png',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },

  // live
  vivogaming: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  evolutionlive: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  ezugi: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  aviator: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '1.svg',
    darkBig: '1.svg',
  },
  flg: {
    lightSmall: '2.svg',
    darkSmall: '2.svg',
    lightBig: '2.svg',
    darkBig: '2.svg',
  },
  pragmaticlive: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  tvbet: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  jacktop: {
    lightSmall: '1.png',
    darkSmall: '1.png',
    lightBig: '1.png',
    darkBig: '1.png',
  },
  xprogaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },

  // slot
  // multi
  outcome: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  evolution: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  softgaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  softgamingslots: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  pragmatic: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  pragmaticnew: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  slotegrator: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  // single
  wmg: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  elbet: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  nolimit: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  betsoft: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  unicum: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  casinotechnology: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  spribe: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  gamomat: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  bigtimegaming: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  luckygames: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  worldmatch: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  conceptgaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  mrslotty: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  wacs: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  reelnrg: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  ganapati: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  platipus: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  pgsoft: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  netent: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  amatic: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  apollo: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  aristocrat: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  booongo: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  cqgaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  evoplay: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  fishing: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  gaminator: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  greentube: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  igrosoft: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  igt: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  kajot: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  austria: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  upgaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  konami: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  merkur: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  microgaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  playson: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  playtech: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  quickspin: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  spadegaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  wazdan: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  fiable: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  habanero: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  gameart: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  fugaso: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  spinomenal: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  tomhorn: {
    lightSmall: '1.png',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  dlv: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  revolvergaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  betsolutions: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  lottoinstantwin: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  belatra: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  kagaming: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  macawgaming: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  endorphina: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  bgaming: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  egt: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  bsw: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  bswgames: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  relaxgaming: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  playngo: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  hacksaw: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  inbet: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  pushgaming: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.png',
    darkBig: '4.png',
  },
  rubiplay: {
    lightSmall: '1.png',
    darkSmall: '1.png',
    lightBig: '1.png',
    darkBig: '1.png',
  },
  casimi: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  spinmatic: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  redrake: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  ssg: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '3.png',
    darkBig: '3.png',
  },
  universalrace: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '1.svg',
    darkBig: '1.svg',
  },

  favorite: {
    lightSmall: '1.svg',
    darkSmall: '1.svg',
    lightBig: '1.svg',
    darkBig: '1.svg',
  },
  economix: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.svg',
    darkBig: '4.svg',
  },
  pragmaticlivecasino: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.svg',
    darkBig: '4.svg',
  },
  pragmaticslots: {
    lightSmall: '1.svg',
    darkSmall: '2.svg',
    lightBig: '3.svg',
    darkBig: '4.svg',
  },
};
