import React, { useState, useCallback, useContext } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalContext } from 'components/ModalProvider/ModalProvider';
import Button from 'components/Button';
import SVG from 'components/SVG';
import Input from 'components/Input';
import GeneratePasswordButton from 'components/GeneratePasswordButton';
import Switcher from 'components/Switcher';
import { actions } from 'features/users/redux';
import { passwordSettingsConfig } from 'shared/config';
import radioSVG from './img/radio.svg';
import radioEmptySVG from './img/radioEmpty.svg';
import './CreateAgent.scss';

const b = block('create-agent');

const CreateAgent = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const history = useHistory();
  const { openModal, closeModal } = useContext(ModalContext);
  const { role } = useSelector(state => state.auth, shallowEqual);

  const [form, setForm] = useState({
    username: '',
    password: '',
    email: '',
    name: '',
    surname: '',
  });
  const [withdrawalAllowed, setWithdrawalAllowed] = useState(true);
  const [depositAllowed, setDepositAllowed] = useState(true);
  const [isVendor, setIsVendor] = useState(true);

  const onSubmit = useCallback(() => {
    closeModal();
    dispatch(
      actions.createAgent({
        form: { ...form, withdrawalAllowed, depositAllowed, role: isVendor ? 7 : 6 },
        callback: () => history.push('/users/all'),
      }),
    );
  }, [closeModal, dispatch, form, withdrawalAllowed, depositAllowed, history, isVendor]);

  const onGeneratePassword = useCallback(password => {
    setForm(data => ({ ...data, password }));
  }, []);

  const openPopUp = useCallback(
    e => {
      e.preventDefault();
      openModal({
        content: (
          <div className={b('confirmation-pop-up')}>
            <div
              className={b('confirmation-pop-up', 'description')}
              dangerouslySetInnerHTML={{
                __html: `${locale.wantToCreateAgent} <span>${form.username}</span>.`,
              }}
            />
            <div className={b('confirmation-pop-up', 'buttons')}>
              <Button color="transparent" size="low" callBack={onSubmit} text={locale.createAgent} />
              <Button callBack={closeModal} text={locale.cancel} size="low" />
            </div>
          </div>
        ),
      });
    },
    [openModal, locale.wantToCreateAgent, locale.createAgent, locale.cancel, form.username, onSubmit, closeModal],
  );

  const changeValue = useCallback(
    e => setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value?.replace(/\s/g, '') }),
    [form],
  );

  const changeValueUsername = useCallback(
    e => setForm({ ...form, username: e.currentTarget.value?.replace(/\s/g, '') }),
    [form],
  );

  if (role !== 6) return null;
  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={openPopUp} autoComplete="off">
        <div className={b('header')}>{locale.createAgent}</div>
        <div className={b('fields')}>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.username}</span>
              <Input placeholder={locale.username} onChange={changeValueUsername} value={form.username} />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.email}</span>
              <Input placeholder={locale.email} onChange={changeValue} value={form.email} name="email" />
            </div>
          </div>
          <div className={b('form', 'input-block', { generator: true })}>
            <div className={b('input')}>
              <GeneratePasswordButton callback={onGeneratePassword} passwordSettings={passwordSettingsConfig.player} />
            </div>
          </div>

          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.name}</span>
            <div className={b('input')}>
              <Input placeholder={locale.name} onChange={changeValue} value={form.name} name="name" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.password}</span>
            <div className={b('input')}>
              <Input placeholder={locale.password} onChange={changeValue} value={form.password} name="password" />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.surname}</span>
            <div className={b('input')}>
              <Input placeholder={locale.surname} onChange={changeValue} value={form.surname} name="surname" />
            </div>
          </div>
        </div>

        <div className={b('form', 'switchers')}>
          <div className={b('form', 'switchers-block')}>
            <div
              className={b('form', 'switchers-title')}
              dangerouslySetInnerHTML={{ __html: locale.withdrawalAllowed }}
            />
            <div className={b('form', 'switchers-item')}>
              <Switcher isActive={withdrawalAllowed} onSwitch={setWithdrawalAllowed} />
            </div>
          </div>
          <div className={b('form', 'switchers-block')}>
            <div className={b('form', 'switchers-title')} dangerouslySetInnerHTML={{ __html: locale.depositAllowed }} />
            <div className={b('form', 'switchers-item')}>
              <Switcher isActive={depositAllowed} onSwitch={setDepositAllowed} />
            </div>
          </div>
          {role === 6 && (
            <div className={b('form', 'radio-block')}>
              <div className={b('form', 'input-block_icon')}>{locale.role}</div>
              <div className={b('radio')}>
                <div className={b('radio-button')} onClick={() => setIsVendor(!isVendor)}>
                  <SVG svgProps={{ svg: isVendor ? radioEmptySVG : radioSVG }} className={b('radio-button-svg')} />
                  <div className={b('radio-text')}>{locale.agent}</div>
                </div>
                <div className={b('radio-button')} onClick={() => setIsVendor(!isVendor)}>
                  <SVG svgProps={{ svg: isVendor ? radioSVG : radioEmptySVG }} className={b('radio-button-svg')} />
                  <div className={b('radio-text')}>{locale.vendor}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button type="submit" size="low" text={locale.createAgent} />
        </div>
      </form>
    </div>
  );
};

export default CreateAgent;
