import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AsyncPollNotifications from 'components/AsyncPoll/AsyncPollNotifications';

import { actions as authAction } from 'features/Auth';
import { actions as userSettingsActions } from 'features/userSettings';
import Notify from 'features/notify/desktop';
import { ModalProvider } from 'components/ModalProvider/ModalProvider';
import Users from 'features/users/desktop';

import General from 'features/reports/view/desktop/Pages/FinancialReports/General';
import Cash from 'features/reports/view/desktop/Pages/FinancialReports/Cash';
import DetailByPlayer from 'features/reports/view/desktop/Pages/PlayerReports/DetailByPlayer';
import PlayerHistory from 'features/reports/view/desktop/Pages/PlayerReports/PlayerHistory';
import SportBets from 'features/reports/view/desktop/Pages/PlayerReports/SportBets';
import { MyDebtAccount, HowToStart, FlatPage } from 'features/finance/desktop';

import { Tree } from 'features/tree';

import Header from 'components/Header/desktop';
import Subheader from 'components/Subheader/desktop';
import Sidebar from 'components/Sidebar/desktop';
import { getTitlePageFromRoute } from 'components/Subheader/data';
import CreatePlayer from 'features/users/view/desktop/Modals/CreatePlayer/CreatePlayer';
import CreateAgent from 'features/users/view/desktop/Modals/CreateAgent/CreateAgent';
import Deposit from 'features/users/view/desktop/Modals/Deposit/Deposit';
import Withdrawal from 'features/users/view/desktop/Modals/Withdrawal/Withdrawal';
import ChangePassword from 'features/users/view/desktop/Modals/ChangePassword/ChangePassword';
import DisableUser from 'features/users/view/desktop/Modals/DisableUser/DisableUser';
import ModifyAgent from 'features/users/view/desktop/Modals/ModifyAgent/ModifyAgent';
import Information from 'features/users/view/desktop/Modals/Information/Information';
import ChangePermissions from 'features/users/view/desktop/Modals/ChangePermissions/ChangePermissions';

import Auth from 'features/Auth/desktop';

import './App.scss';

const App = () => {
  const b = block('app');
  const isAuth = useSelector(state => state.auth.isAuth);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  const getTitle = () => {
    return getTitlePageFromRoute.find(el => location.pathname.includes(el.route));
  };
  const title = getTitle();

  return (
    <ModalProvider>
      <div className={b()}>
        <Notify />

        {isAuth ? (
          <>
            <AsyncPollNotifications />
            <Sidebar />
            <div className={b('wrapper')}>
              <main className={b('wrapper', 'main')}>
                <div className={b('header')}>
                  <Header />
                </div>
                {!title?.userPage && <Subheader />}

                <div className={b('wrapper', 'content')}>
                  <Switch>
                    <Route exact key="/users" path="/users" component={Users} />
                    <Route key="/users/:tab" path="/users/:tab" component={Users} />
                    <Route exact key="/user/create-player" path="/user/create-player" component={CreatePlayer} />
                    <Route exact key="/user/create-agent" path="/user/create-agent" component={CreateAgent} />
                    <Route exact key="/user/deposit" path="/user/deposit/:userId" component={Deposit} />
                    <Route exact key="/user/withdrawal" path="/user/withdrawal/:userId" component={Withdrawal} />
                    <Route exact key="/user/enable/:userId" path="/user/enable/:userId" component={DisableUser} />
                    <Route
                      exact
                      key="/user/change-password/:userId"
                      path="/user/change-password/:userId"
                      component={ChangePassword}
                    />

                    <Route
                      exact
                      key="/user/disable/:userId"
                      path="/user/disable/:userId"
                      render={() => <DisableUser isDisable />}
                    />
                    <Route exact key="/user/edit/:userId" path="/user/edit/:userId" component={ModifyAgent} />
                    <Route exact key="/user/info/:userId" path="/user/info/:userId" component={Information} />

                    <Route
                      exact
                      key="/reports/financial-reports/general"
                      path="/reports/financial-reports/general"
                      component={General}
                    />
                    <Route
                      exact
                      key="/reports/financial-reports/cash"
                      path="/reports/financial-reports/cash"
                      component={Cash}
                    />
                    <Route
                      exact
                      key="/reports/player-reports/detail-by-player"
                      path="/reports/player-reports/detail-by-player"
                      component={DetailByPlayer}
                    />
                    <Route
                      exact
                      key="/reports/player-reports/player-history"
                      path="/reports/player-reports/player-history"
                      component={PlayerHistory}
                    />
                    <Route
                      exact
                      key="/reports/player-reports/sport-bets"
                      path="/reports/player-reports/sport-bets"
                      component={SportBets}
                    />

                    <Route
                      exact
                      key="/finance/my-debt-account"
                      path="/finance/my-debt-account"
                      component={MyDebtAccount}
                    />

                    <Route
                      exact
                      key="/user/permissionsDetails/:userId"
                      path="/user/permissionsDetails/:userId"
                      component={ChangePermissions}
                    />

                    <Route exact key="/finance/how-to-start" path="/finance/how-to-start" component={HowToStart} />
                    <Route
                      exact
                      key="/finance/how-to-start/flat/:page"
                      path="/finance/how-to-start/flat/:page"
                      component={FlatPage}
                    />

                    <Redirect from="/" to="/users/all" />
                  </Switch>
                  {!title?.userPage && (
                    <div className={b('user-tree')}>
                      <Tree />
                    </div>
                  )}
                </div>
              </main>
            </div>
          </>
        ) : (
          <Switch>
            <Route exact key="/" path="/" component={Auth} />
            <Redirect to="/" />
          </Switch>
        )}
      </div>
    </ModalProvider>
  );
};

export default App;
