import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import Input from 'components/Input';
import useTree from 'hooks/useTree';

import DaysFilter from '../../../../DaysFilter';

import { structure } from '../../../../../../data';

import { actions } from 'features/reports/redux';
import { actions as userActions } from 'features/users/redux';


import './FilterBlock.scss';
// import SearchInput from 'components/SearchInput';

const b = block('filter-block');

const FilterBlock = ({ isMobile }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const { getTreeById } = useTree();
  const [filterValue, onChangeFilterValue] = useState('');
  const [date, setDate] = useState({
    beginDate: dayjs().subtract(1, 'week').startOf('day').toDate(),
    endDate: dayjs().startOf('day').toDate(),
  });
  const [activeDay, setActiveDay] = useState('');
  const [searchResults, setSearchResults] = useState(null)


  const [filters, setFilters] = useState({
    structure: structure[0],
  });

  const getUsers = useCallback(data => {
    return dispatch(userActions.getUsersListForSearch(data));
  }, [dispatch]);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const changeFilterValue = useCallback(value => {
    onChangeFilterValue(value.toLowerCase());
  }, []);

  const handleStructureChange = value => {
    const structureItem = structure.find(item => item.value === value);
    setFilters({ ...filters, structure: structureItem });
  };

  const handleSearch = useCallback(() => {
    filterValue.length > 0 && getTreeById(filterValue);
    dispatch(actions.getStatisticProvider({ ...date, ...filters, username: filterValue }));
  });

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, [date]);

  const handleSearchUser = useCallback(
    async (value) => {
      changeFilterValue(value);

      if (value) {
        try {
          const data = await getUsers({ username: value });
          setSearchResults(data);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [changeFilterValue, getUsers]
  );

  return (
    <div className={b({ isMobile })}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('wrapper')}>
        <div className={b('left')}>
          <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} isMobile={isMobile} />
          <div className={b('inputs')}>
            <div className={b('input')}>
              <div className={b('title')}>{locale.show}</div>
              <Select
                name="structure"
                items={structure}
                activeItem={filters.structure}
                onChange={handleStructureChange}
                // color="transparent"
              />
            </div>
            {/* <div className={b('input')}>
              <div className={b('title')}>{locale.user}</div>
              <SearchInput
                value={filterValue}
                items={searchResults}
                onChange={handleSearchUser}
                placeholder={locale.user}
                name="agent"
              />
            </div> */}
          </div>
        </div>
        <div className={b('or')}>or</div>
        <div className={b('right')}>
          <div className={b('date')}>
            <div className={b('date-row')}>
              <div className={b('title')}>{locale.dateFrom}</div>
              <div className={b('date-input')}>
                <InputDate showTimeSelect selected={date.beginDate} onChange={onChangeBeginDate} color="black" />
              </div>
            </div>
            <div className={b('date-row')}>
              <div className={b('title')}>{locale.dateTo}</div>
              <div className={b('date-input')}>
                <InputDate showTimeSelect selected={date.endDate} onChange={onChangeEndDate} color="black" />
              </div>
            </div>
          </div>
          <div className={b('button')}>
            <Button size="low" callBack={() => handleSearch()} text={locale.acceptFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
