import React, { useState, useCallback, useContext } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'components/ModalProvider/ModalProvider';
import GeneratePasswordButton from 'components/GeneratePasswordButton';
import Button from 'components/Button';
import Input from 'components/Input';
import { actions } from 'features/users/redux';
import { passwordSettingsConfig } from 'shared/config';

import './CreatePlayer.scss';

const b = block('create-player');

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { openModal, closeModal } = useContext(ModalContext);

  const [form, setForm] = useState({
    username: '',
    password: '',
    email: '',
    name: '',
    surname: '',
  });

  const onSubmit = useCallback(() => {
    closeModal();
    dispatch(actions.createUser({ form, callback: () => history.push('/users/all') }));
  }, [closeModal, dispatch, form, history]);

  const onGeneratePassword = useCallback(password => {
    setForm(data => ({ ...data, password }));
  }, []);

  const openPopUp = useCallback(
    e => {
      e.preventDefault();
      openModal({
        content: (
          <div className={b('confirmation-pop-up')}>
            <div
              className={b('confirmation-pop-up', 'description')}
              dangerouslySetInnerHTML={{
                __html: `${locale.wantToCreateAgent} <span> ${form.username}</span>.`,
              }}
            />
            <div className={b('confirmation-pop-up', 'buttons')}>
              <Button color="transparent" size="low" callBack={onSubmit} text={locale.createPlayer} />
              <Button callBack={closeModal} text={locale.cancel} size="low" />
            </div>
          </div>
        ),
      });
    },
    [openModal, locale.wantToCreateAgent, locale.createPlayer, locale.cancel, form.username, onSubmit, closeModal],
  );

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value?.replace(/\s/g, '') });
    },
    [form],
  );

  const changeValueUsername = useCallback(
    e => setForm({ ...form, username: e.currentTarget.value?.replace(/\s/g, '') }),
    [form],
  );

  return (
    <div className={b()}>
      <form className={b('form')} onSubmit={openPopUp} autoComplete="off">
        <div className={b('header')}>{locale.createPlayer}</div>
        <div className={b('fields')}>
          <div className={b('form', 'input-block')}>
            <div className={b('input')}>
              <span className={b('form', 'input-block-title')}>{locale.username}</span>
              <Input
                placeholder={locale.username}
                onChange={changeValueUsername}
                size="low"
                type="text"
                value={form.username}
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.surname}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.surname}
                onChange={changeValue}
                size="low"
                type="text"
                value={form.surname}
                name="surname"
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.name}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.name}
                onChange={changeValue}
                size="low"
                type="text"
                value={form.name}
                name="name"
              />
            </div>
          </div>
          <div className={b('form', 'input-block', { generator: true })}>
            <div className={b('input')}>
              <GeneratePasswordButton callback={onGeneratePassword} passwordSettings={passwordSettingsConfig.player} />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.email}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.email}
                onChange={changeValue}
                size="low"
                type="text"
                value={form.email}
                name="email"
              />
            </div>
          </div>
          <div className={b('form', 'input-block')}>
            <span className={b('form', 'input-block-title')}>{locale.password}</span>
            <div className={b('input')}>
              <Input
                placeholder={locale.password}
                onChange={changeValue}
                size="low"
                type="text"
                value={form.password}
                name="password"
              />
            </div>
          </div>
        </div>

        <div className={b('bottom')}>
          <Button color="full-transparent" callBack={history.goBack} text={locale.cancel} size="low" />
          <Button type="submit" size="low" text={locale.createPlayer} />
        </div>
      </form>
    </div>
  );
};

export default CreatePlayer;
