import React, { useCallback } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { generatePassword } from 'services/helper/generatePassword';
import SVG from 'components/SVG';

import shieldSVG from './img/shield.svg';

import './GeneratePasswordButton.scss';

const b = block('generate-password-button');

const GeneratePasswordButton = ({ callback, passwordSettings }) => {
  const locale = useSelector(state => state.locale.locale);

  const onGeneratePassword = useCallback(() => {
    const password = generatePassword(passwordSettings);
    callback(password);
  }, [passwordSettings]);

  return (
    <div onClick={onGeneratePassword} className={b()}>
      {locale.securePassword}
      <SVG className={b('icon')} svgProps={{ svg: shieldSVG }} />
    </div>
  );
};

export default GeneratePasswordButton;
