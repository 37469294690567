import React, { useMemo, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';
import Paginator from 'components/Paginator';
import TableRow from '../TableRow/TableRow';

import './Table.scss';

const b = block('users-table');

const Table = ({ locale, currentPage, onPageClick, itemsOnPage, filterValue }) => {
  const { tab } = useParams();
  const totalCount = useSelector(state => state.users.usersList.totalCount, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);
  const auth = useSelector(state => state.auth);

  const [openDropdown, setOpenDropdown] = useState({ userID: null, isOpen: false });

  const users = useMemo(() => {
    const list = usersList.users;
    if (list.length && list[0].id === auth.id) return list.slice(1);
    return list;
  }, [usersList.users, auth.id]);

  const rows = useMemo(
    () =>
      users
        .filter(t => ~t.username?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .filter(el => {
          if (tab === 'disabled') {
            return el.isBanned;
          } else if (tab === 'all') {
            return !el.isBanned;
          }
          return true;
        })
        .map(item => (
          <TableRow
            isWithdrawalAccess={auth.isWithdrawalAccess}
            isDepositAccess={auth.isDepositAccess}
            key={item.id}
            item={item}
            locale={locale}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )),
    [users, tab, auth, filterValue, locale, openDropdown],
  );

  return (
    <div className={b({ tab })}>
      <div className={b('table')}>
        <div className={b('table-header')}>
          <div className={b('table-header-head')}>{locale.user}</div>
          <div className={b('table-header-head')}>{locale.balance}</div>
          <div className={b('table-header-head')}>{locale.operations}</div>
          <div className={b('table-header-head')}>{locale.actions}</div>
        </div>
        <div className={b('tbody')}>
          {!!rows.length ? rows : <div className={b('no-results')}>{locale.noResults}</div>}
        </div>
      </div>
      <div className={b('paginator-wrapper')}>
        <div className={b('extra-info')}>
          {totalCount} {locale.records}
        </div>

        <Paginator count={totalCount} currentPage={currentPage} onPageClick={onPageClick} itemsOnPage={itemsOnPage} />
      </div>
    </div>
  );
};

Table.propTypes = {
  // usersList: PropTypes.array.isRequired,
  // count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.object.isRequired,
  onPageClick: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
};

export default Table;
