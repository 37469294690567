const passwordSettingsConfig = {
  player: {
    active: true,
    min_length: 6,
    digits: {
      amount: 3,
    },
    letters: {
      amount: 3,
      uppercase: 2,
      lowercase: 2,
    },
    special_characters: {
      amount: 0,
    },
  },
  agent: {
    active: true,
    min_length: 6,
    digits: {
      amount: 3,
    },
    letters: {
      amount: 3,
      uppercase: 2,
      lowercase: 2,
    },
    special_characters: {
      amount: 0,
    },
  },
};

export { passwordSettingsConfig };
