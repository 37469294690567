/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import SVG from 'components/SVG';
import Switcher from 'components/Switcher';

import { actions } from 'features/users/redux';
import detailsSVG from 'features/users/img/details.svg';
import dropdownSVG from 'features/users/img/dropdown.svg';

import { permissionsDetailsData } from '../../../../../data';

import './PermissionsDetailsItems.scss';

const b = block('permissions-details-items-desktop ');

const PermissionsDetailsItems = ({ item, userId, userInfo, permissionsForAgent, setPermissionsForAgent }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { bannedProviders } = useSelector(state => state.users);
  const [isOpen, changeOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(
    permissionsDetailsData.reduce((acc, curr) => ((acc[curr.category] = false), acc), {}),
  );

  const checkIsCategoryBlocked = useCallback(
    category => {
      const categoryItem = bannedProviders?.lockData?.find(item => item.name === category);
      return categoryItem
        ? categoryItem.isOnlyPlayers ||
            categoryItem.isFullBranch ||
            (!categoryItem.isOnlyPlayers && !categoryItem.isFullBranch)
        : false;
    },
    [bannedProviders],
  );

  const checkIsProviderBanned = useCallback(
    provider => {
      const providerItem = bannedProviders?.blockList?.find(item => {
        return item === provider;
      });
      return providerItem
        ? providerItem.isOnlyPlayers ||
            providerItem.isFullBranch ||
            (!providerItem.isOnlyPlayers && !providerItem.isFullBranch)
        : false;
    },
    [bannedProviders],
  );

  const findCategory = item =>
    permissionsDetailsData.filter(categoryItem => categoryItem.items.includes(item))[0].category;

  const handleSetProviderState = data => dispatch(actions.setBannedProviders({ data }));

  const getIsFullBranch = useCallback(
    (target, isCategory = false) => {
      if (userInfo.role === '0') return false;
      return isCategory
        ? bannedProviders?.lockData?.find(item => item.name === target)?.isFullBranch || true
        : bannedProviders?.lockData?.find(item => item.name === target)?.isFullBranch || true;
    },
    [bannedProviders],
  );

  const getIsOnlyPlayers = useCallback(
    (target, isCategory = false) => {
      if (userInfo.role === '0') return true;
      return isCategory
        ? bannedProviders?.blocklist?.find(item => item.name === target)?.isOnlyPlayers || true
        : bannedProviders?.blocklist?.find(item => item.name === target)?.isOnlyPlayers || true;
    },
    [bannedProviders],
  );

  return (
    <div className={b()} key={item.category}>
      <div className={b('item')}>
        <div className={b('arrow-open', { open: isOpen })} onClick={() => changeOpen(!isOpen)}>
          <SVG
            onClick={() => setCategoryOpen({ ...categoryOpen, [item.category]: !categoryOpen[item.category] })}
            containerClassName={b('item-dropdown')}
            svgProps={{ svg: dropdownSVG }}
          />
        </div>
        <div className={b('item-title')}>{locale[item.category]}</div>
        <div className={b('item-container')}>
          <div className={b('item-switcher')}>
            <Switcher
              isActive={!checkIsCategoryBlocked(item.category)}
              onSwitch={() =>
                handleSetProviderState({
                  providers: item.items.includes('pragmatic-live-casino') ? [item.items, 'pragmatic-bj'] : item.items,
                  action: checkIsCategoryBlocked(item.category) ? 'remove' : 'add',
                  isFullBranch: true,
                  id: userId,
                  category: item.category,
                })
              }
            />
          </div>
          <>
            {userInfo.role !== '0' && (
              <SVG
                onClick={() =>
                  setPermissionsForAgent({
                    ...permissionsForAgent,
                    isOpen: true,
                    category: item.category,
                    enabled: !checkIsCategoryBlocked(item.category),
                    items: item.items,
                    isChildItem: false,
                  })
                }
                containerClassName={b('item-details')}
                svgProps={{ svg: detailsSVG }}
              />
            )}
          </>
        </div>
      </div>
      {categoryOpen[item.category] &&
        item.items.map((item, index) => {
          return (
            <div key={index} className={b('item', { inner: true })}>
              <div className={b('item-container-title')}>
                <div className={b('item-title', { inner: true })}>{item.toLowerCase()}</div>
              </div>
              <div className={b('item-container')}>
                <div className={b('item-switcher')}>
                  <Switcher
                    isActive={!checkIsProviderBanned(item)}
                    onSwitch={() =>
                      handleSetProviderState({
                        providers: item === 'pragmatic-live-casino' ? [item, 'pragmatic-bj'] : [item],
                        action: checkIsProviderBanned(item) ? 'remove' : 'add',
                        isFullBranch: true,
                        id: userId,
                        category: findCategory(item),
                      })
                    }
                  />
                </div>
                {userInfo.role !== '0' && (
                  <SVG
                    onClick={() =>
                      setPermissionsForAgent({
                        ...permissionsForAgent,
                        isOpen: true,
                        category: findCategory(item),
                        enabled: !checkIsProviderBanned(item),
                        items: [item],
                        isChildItem: true,
                      })
                    }
                    containerClassName={b('item-details')}
                    svgProps={{ svg: detailsSVG }}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PermissionsDetailsItems;
