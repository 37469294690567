import React, { useCallback, useState } from 'react';
import { block } from 'bem-cn';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { actions } from 'features/users/redux';
import SVG from 'components/SVG';
// import useTree from 'hooks/useTree';
import searchSvg from './img/search.svg';
import SearchInput from 'components/SearchInput';

import './UsersFilterBlock.scss';

const b = block('users-filter-block');

const UsersFilterBlock = ({
  filterValue,
  changeFilterValue,
  callBack = null,
  actionProcessing,
  withoutSearchIcon = false,
  placeholder = null,
  role,
  isBanned,
}) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  // const { getTreeById } = useTree();

  const [searchResults, setSearchResults] = useState(null);
  const [inputValue, setInputValue] = useState(filterValue);

  const getUsers = useCallback(
    async data => {
      try {
        const users = await dispatch(actions.getUsersListForSearch(data));
        setSearchResults(users);
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch],
  );

  const handleSearch = useCallback(
    value => {
      setInputValue(value);
      if (value) {
        getUsers({ username: value, role: String(role), is_banned: isBanned });
      }
    },
    [getUsers, role, isBanned],
  );

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        changeFilterValue(inputValue);
        setInputValue(inputValue);
      }
    },
    [changeFilterValue, inputValue],
  );

  const handleButtonClick = event => {
    event.preventDefault();
    callBack(inputValue);
  };

  return (
    <div className={b()}>
      <div className={b('input')}>
        <SearchInput
          value={inputValue}
          items={searchResults}
          onChange={handleSearch}
          placeholder={placeholder ?? `${locale.search} ${locale.user}`}
          onKeyDown={handleKeyPress}
        />
      </div>
      {!withoutSearchIcon && (
        <button disabled={actionProcessing} className={b('icon-wrapper')} onClick={handleButtonClick}>
          <SVG svgProps={{ svg: searchSvg }} className={b('icon').toString()} />
        </button>
      )}
    </div>
  );
};

export default UsersFilterBlock;
