import React from 'react';
import block from 'bem-cn';

import './TableHead.scss';

const b = block('detail-player-table-head-pagination');

const TableHead = ({ items, locale }) => {

  return (
    <div className={b()}>
      {items?.map(el => (
        <div key={el} className={b('table-head')}>
          {locale[el]}
        </div>
      ))}
    </div>
  );
};

export default TableHead;
