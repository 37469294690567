import { addNotify } from 'features/notify';
import dayjs from 'dayjs';

import { getErrorMessage } from 'shared/helpers/getErrorMessage';

const actionTypes = {
  ACTION_PROCESSING: 'reports/ACTION_PROCESSING',
  GET_STATISTIC_PROVIDER: 'reports/GET_STATISTIC_PROVIDER',
  GET_PAYMENT_HISTORY: 'reports/GET_PAYMENT_HISTORY',
  GET_USER_STATISTICS: 'reports/GET_USER_STATISTICS',
  GET_SLOTS_HISTORY: 'reports/GET_SLOTS_HISTORY',
  GET_BETS_HISTORY: 'reports/GET_BETS_HISTORY',
  SET_PLAYER_USERNAME: 'reports/SET_PLAYER_USERNAME',
  GET_AGENT_PLAYER_STATISTICS: 'reports/SET_PLAYER_USERNAME',
  GET_DETAILS_BET_HISTORY: 'reports/GET_DETAILS_BET_HISTORY'
};

function getStatisticProvider({ username, ...form }) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;
    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DDTHH:mm:ss'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      is_direct_only: form.structure.name === 'directOnly' ? true : false,
    };
    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
      const response = await api.reports.getStatisticProvider({ id: selectedAgentId, requestedBody });
      if (response.success) {
        dispatch({
          type: actionTypes.GET_STATISTIC_PROVIDER,
          payload: response.data,
        });
      } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
      }
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else return;
  };
}

function getPaymentHistory(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;
    // const lastUser = getState().users.usersList?.users[0];
    // const isAgent = +lastUser?.role === 6;
    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DDTHH:mm:ss'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      username: form.username,
      role: form.role,
      is_direct_structure: form.isDirectOnly,
      is_higher_transaction_only: form.isHigher,
      is_withdrawal_transfers: form.isWithdrawal,
      is_deposit_transfers: form.isDeposit,
      page: form.page,
      is_received: form.isReceived,
      to_username: form.isToUsername ? form.username : undefined,
      count: form.count,
    };
    // const name = form.username || lastUser.username;
    // const agentBody = { ...requestedBody, user_login: name };
    // const playerBody = {
    //   ...requestedBody,
    //   username: name,
    //   // user_login: form.upperAgentUsername,
    // };
    // const parsedBody = isAgent ? agentBody : playerBody;
    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
      const response = await api.reports.getPaymentHistory({ id: selectedAgentId, requestedBody });
      if (response.success) {
        dispatch({
          type: actionTypes.GET_PAYMENT_HISTORY,
          payload: response.data,
        });
      } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
      }
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      return;
    }
  };
}

function getStatisticPlayer(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;
    // const lastUser = getState().users.usersList?.users[0];
    // const isAgent = +lastUser?.role === 6 || +lastUser?.role === 1;
    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DDTHH:mm:ss'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      username: form.username,
      is_direct_structure: form.isDirectOnly,
      page: form.page || 0,
      count: form.count || 10,
    };

    // const name = form?.username;

    // const agentBody = {
    //   ...requestedBody,
    //   // user_login: name
    // };
    // const playerBody = {
    //   ...requestedBody,
    //   username: name,
    //   // user_login: form.upperAgentUsername,
    // };
    // const parsedBody = isAgent ? agentBody : playerBody;

    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
      const response = await api.reports.getStatisticPlayer({ id: selectedAgentId, requestedBody });
      if (response.success) {
        dispatch({
          type: actionTypes.GET_USER_STATISTICS,
          payload: response.data,
        });
      } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
      }
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      return;
    }
  };
}

function getUsersForAgent(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;

    const id = form.user_id;
    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.reports.getStatisticPlayer({ id, requestedBody });

    if (response.success) {
      const updatedDetails = {
        ...response.data.details,
        agentName: form.agentName,
      };

      const updatedData = {
        ...response.data,
        details: updatedDetails
      };

      dispatch({
        type: actionTypes.GET_AGENT_PLAYER_STATISTICS,
        payload: updatedData,
      });
    } else {
      const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });

  };
}

function getSlotsHistory(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { id } = getState().auth;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DDTHH:mm:ss'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      username: form.username || null,
      page: form.page,
      count: form.count,
      game_type: form.gameType === 'all' ? null : form.gameType,
      provider: form.provider || null,
      status: form.status,
    };

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

      const response = await api.reports.getSlotsHistory({ id, requestedBody });

      if (response.success) {
        dispatch({
          type: actionTypes.GET_SLOTS_HISTORY,
          payload: response.data,
        });
      } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });

  };
}

function getBetsHistory(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;
    const lastUser = getState().users.usersList?.users[0];
    const isAgent = Number(lastUser?.role) === 6;
    const name = form?.id || lastUser?.id;


    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DDTHH:mm:ss'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      page: form.page,
      count: form.count,
      // game_type: 'sport',
      type: form.type,
      status: form.status,
      ...(form.betId ? { coupon_id: form.betId } : {}),
      ...(form.userId ? { user_id: form.userId } : {}),
    };

    const agentBody = {
      ...requestedBody,
      // user_id: selectedAgentId
    };
    const playerBody = {
      ...requestedBody,
      username: name,
      // user_id: selectedAgentId,
    };
    const parsedBody = isAgent ? agentBody : playerBody;
    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
      // const response = await api.reports.getBetsHistory({ id: selectedAgentId, requestedBody });
      //for /bets/history
      const response = await api.reports.getBetsHistory({ id: selectedAgentId, requestedBody: parsedBody });
      if (response.success) {
        dispatch({
          type: actionTypes.GET_BETS_HISTORY,
          payload: response.data,
        });
      } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else return;
  };
}

function getDetailsBetHistory(id) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const response = await api.reports.getDetailsBetHistory(id);

    if (response.success) {
      dispatch({
        type: actionTypes.GET_DETAILS_BET_HISTORY,
        payload: { id, data: response.data },
      });
    } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });

  };
}

function setPlayerUsername(username, role) {
  return async dispatch => {
    dispatch({ type: actionTypes.SET_PLAYER_USERNAME, payload: { username, role: role } });
  };
}

export {
  actionTypes,
  getStatisticProvider,
  getPaymentHistory,
  getStatisticPlayer,
  getSlotsHistory,
  getBetsHistory,
  setPlayerUsername,
  getUsersForAgent,
  getDetailsBetHistory
};
