import React, { useState, useEffect, useCallback, useMemo } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router';

import { actions } from 'features/users/redux';
// import { actions as treeActions } from 'features/tree';
import Select from 'components/Select/desktop';

import UsersFilterBlock from './UsersFilterBlock';
import Table from './Table/Table';
import Tabs from './Tabs/Tabs';

import './Users.scss';

const b = block('users');

const Users = () => {
  const dispatch = useDispatch();

  const [itemsOnPage, setItemsOnPage] = useState({ value: 10, name: '10' });
  const [actionProcessing, setActionProcessing] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const { tab } = useParams();

  const auth = useSelector(state => state.auth);
  const { isNeedToRefresh } = useSelector(state => state.users);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { selectedAgentId } = useSelector(state => state.tree);

  const itemsOnPageFilters = [
    { value: 10, name: '10' },
    { value: 25, name: '25' },
    { value: 50, name: '50' },
    { value: 100, name: '100' },
  ];

  const { email, id } = auth;

  const role = useMemo(() => {
    switch (tab) {
      case 'players':
        return 0;
      case 'agents':
        return 6;
      case 'cashiers':
        return 1;
      case 'all':
      case 'disabled':
        return '';
      default:
        setPage(0);
        return '';
    }
  }, [tab]);

  const isBanned = useMemo(() => tab === 'disabled', [tab]);

  const getUsers = useCallback(data => dispatch(actions.getUsersList(data)), [dispatch]);

  const handleItemsOnPageChange = itemValue => {
    setItemsOnPage({ value: itemValue, name: itemValue });
    setCount(itemValue);
    setPage(0);
  };

  const getUsersAfterSearch = name => {
    setActionProcessing(true);
    getUsers({ username: name || '', role, is_banned: isBanned, count, page });
    setActionProcessing(false);
  };

  const changeFilterValue = useCallback(value => {
    setFilterValue(value.toLowerCase());
    setActionProcessing(false);
  }, []);

  useEffect(() => {
    getUsers({ username: '', role, userId: selectedAgentId || id, is_banned: isBanned, count, page });
  }, [dispatch, email, role, id, isBanned, count, page, isNeedToRefresh, selectedAgentId, getUsers]);

  useEffect(() => {
    setPage(0);
  }, [selectedAgentId]);

  return (
    <div className={b()}>
      <div className={b('center')}>
        <Tabs role={auth.role} onInitialPage={() => setPage(0)} />
        <div className={b('center-controller')}>
          <UsersFilterBlock
            callBack={getUsersAfterSearch}
            actionProcessing={actionProcessing}
            filterValue={filterValue}
            changeFilterValue={changeFilterValue}
            role={role}
            isBanned={isBanned}
          />
          <div className={b('page-count')}>
            <div className={b('page-count-title')}>{locale.recordsOnPage}:</div>
            <div className={b('page-count-select')}>
              <Select
                paginator
                items={itemsOnPageFilters}
                activeItem={itemsOnPage}
                onChange={handleItemsOnPageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        filterValue={filterValue}
        locale={locale}
        currentPage={page}
        onPageClick={setPage}
        itemsOnPage={itemsOnPage}
      />
    </div>
  );
};

export default Users;
