import { addNotify } from 'features/notify';
import dayjs from 'dayjs';
import { shortLanguages } from 'services/locale';

import { getErrorMessage } from 'shared/helpers/getErrorMessage';

const actionTypes = {
  ACTION_PROCESSING: 'finance/ACTION_PROCESSING',
  GET_FINANCE_HISTORY: 'finance/GET_FINANCE_HISTORY',
  GET_FLAT_PAGES: 'finance/GET_FLAT_PAGES',
};

const fieldsMap = {
  isOnlyPersonalHigherTransfers: 'only_personal_higher_transfers',
  isOnlyUserTransfers: 'only_user_transfers',
  isDeposits: 'is_deposit',
  isWithdrawals: 'is_withdrawal',
};

function getFinanceHistory(form) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { selectedAgentId } = getState().tree;

    const checkboxes = form?.checkboxes || {};

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DDTHH:mm:ss'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      page: form.page,
      count: form.count,
    };

    Object.entries(checkboxes).forEach(([key, value]) => {
      if (checkboxes[key]) {
        requestedBody[fieldsMap[key]] = true;
      }
    });

    if (selectedAgentId) {
      const response = await api.finance.getFinanceHistory({ id: selectedAgentId, requestedBody });
      if (response.success) {
        dispatch({
          type: actionTypes.GET_FINANCE_HISTORY,
          payload: response.data,
        });
      } else {
        const { locale } = getState().locale;
        const errorMessage = getErrorMessage(locale, response);
        dispatch(addNotify(errorMessage, 'error'));
        dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
      }
    } else return;
  };
}

function getFlatPages(form) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const { lang } = getState().locale;

    const response = await api.finance.getFlatPages({ lang: shortLanguages[lang] });
    if (response.success) {
      dispatch({
        type: actionTypes.GET_FLAT_PAGES,
        payload: response.data,
      });
    } else {
      const { locale } = getState().locale;
      const errorMessage = getErrorMessage(locale, response);
      dispatch(addNotify(errorMessage, 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

export { actionTypes, getFinanceHistory, getFlatPages };
