import React, { useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LanguageDropdownSelector from 'components/LanguageDropdownSelector/desktop';
import HeaderDropdown from './HeaderDropdown';

import userSVG from '../img/user-desktop.svg';
// import whatsappSVG from '../img/whatsapp.svg';
import arrowSVG from '../img/arrow.svg';
import bellSVG from 'shared/img/bell.svg';

import './Header.scss';
import NotifiesView from './NotifiesView';

const Header = () => {
  const b = block('header');
  const [headerDropdownVisible, setHeaderDropdownVisible] = useState(false);
  const auth = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale);

  const [isNotifiesOpen, setIsNotifiesOpen] = useState(false);

  const handleCloseNotifies = () => {
    setIsNotifiesOpen(false);
  }

  const handleOpenNotifies = () => {
    setIsNotifiesOpen(!isNotifiesOpen);
  }

  return (
    <div className={b({ open: headerDropdownVisible })}>
      {/* <a className={b('whatsapp')} href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <SVGInline svg={whatsappSVG} className={b('whatsapp-icon').toString()} />
      </a> */}
      <div className={b('wrapper-notifies')}>
        <div className={b('notifies-wrapper')} onClick={handleOpenNotifies}>
          {auth.notifications?.length > 0 && (
            <div className={b('notifies-count')}>
              {auth.notifications?.length}
            </div>
          )}
          <SVGInline className={b('bell').toString()} svg={bellSVG} />
        </div>
        {isNotifiesOpen && (
          <div className={b('notifies')}>
            <NotifiesView closeFunction={handleCloseNotifies} />
          </div>
        )}
      </div>
      <div className={b('language-selector')}>
        <LanguageDropdownSelector isHeader />
      </div>
      <div onClick={() => setHeaderDropdownVisible(!headerDropdownVisible)} className={b('user')}>
        <div className={b('user-info')}>
          <span className={b('user-info-id')}>{`${locale.id}: ${auth.id}`}</span>
          <span className={b('user-info-login')}>{`${auth.username}`}</span>
        </div>
        <SVGInline svg={userSVG} className={b('user-img').toString()} />
        <SVGInline svg={arrowSVG} className={b('user-arrow').toString()} />
      </div>
      {headerDropdownVisible && <HeaderDropdown changeOpen={setHeaderDropdownVisible} />}
    </div>
  );
};

export default withRouter(Header);
