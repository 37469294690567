import React, { useMemo } from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import SVG from 'components/SVG';
import actionsSVG from './img/actions.svg';
import './Dropdown.scss';

const b = block('dropdown');

const Dropdown = ({ items, locale, user, isMobile = false, onClick, isOpen, changeOpen }) => {

  const itemsList = useMemo(
    () =>
      items?.map((item, index) => {
        return item.title === 'lastMovements' ? (
          <div onClick={onClick} key={index} className={b('item')}>
            <SVG className={b('item-icon').toString()} svgProps={{ svg: item.svg }} />
            <span className={b('item-value')}>{locale[item.title]}</span>
          </div>
        ) : (
          <Link to={`${item.link}/${user.id}`} key={index} className={b('item')}>
            <SVG className={b('item-icon').toString()} svgProps={{ svg: item.svg }} />
            <span className={b('item-value')}>{locale[item.title]}</span>
          </Link>
        );
      }),
    [items, onClick, locale, user.id],
  );

  return (
    <div className={b({ open: isOpen, isMobile })} onClick={changeOpen}>
      <div className={b('action')}>
        <SVG className={b('action-icon').toString()} svgProps={{ svg: actionsSVG }} />
      </div>
      {isOpen && (
        <ul className={b('items')} onMouseLeave={changeOpen}>
          {itemsList}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
