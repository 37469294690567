import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import FilterBlock from './FilterBlock';
import ProfileBlock from './ProfileBlock';

import Table from './Table/Table';

import './MyDebtAccount.scss';

const b = block('my-debt-account');

const MyDebtAccount = () => {
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const locale = useSelector(state => state.locale.locale);
  const financeHistory = useSelector(state => state.finance.financeHistory, shallowEqual);
  const { currency } = useSelector(state => state.auth);
  const details = useSelector(state => state.finance.financeHistory.details);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };
  return (
    <div className={b()}>
      <ProfileBlock />
      <div className={b('header')}>{locale.myOperations}</div>
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} />

      <div className={b('header')}>{locale.actualBalance}</div>
      <div className={b('details')}>
        <div className={b('details-item')}>
          <span className={b('type')}>{locale.totalDeposit}</span>
          <div className={b('value')}>
            <span className={b('amount')}>{details?.totalDeposit.toFixed(2)}</span>
            <span className={b('currency')}>{currency}</span>
          </div>
        </div>

        <div className={b('details-item')}>
          <span className={b('type')}>{locale.totalWithdraw}</span>
          <div className={b('value')}>
            <span className={b('amount')}>{details?.totalWithdraw.toFixed(2)}</span>
            <span className={b('currency')}>{currency}</span>
          </div>
        </div>

        <div className={b('details-item')}>
          <span className={b('type')}>{locale.saldo}</span>
          <div className={b('value')}>
            <span className={b('amount')}>{details?.deltaBalance.toFixed(2)}</span>
            <span className={b('currency')}>{currency}</span>
          </div>
        </div>
      </div>
      <Table
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        thTdItems={financeHistory.transfers}
        totalCount={financeHistory.details.totalCount}
        type="my-debt-account"
      />
    </div>
  );
};

export default MyDebtAccount;
