import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import SVG from 'components/SVG';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import arrowSVG from './img/arrow-right.svg';

import './TableRow.scss';

const TableRow = ({ type, item, locale }) => {
  const b = block('table-row-cash');


  if (type === 'my-debt-account') {
    return (
      <div className={b('tr')}>
        <div className={b('td')}>
          <span className={b('td-el')}>{item.id}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{item.date}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{item.fromUser}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{item.toUser}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{item.transitionType}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{formatNumberWithLimit(item.amount)}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{formatNumberWithLimit(item.balanceBefore)}</span>
        </div>
        <div className={b('td')}>
          <span className={b('td-el')}>{formatNumberWithLimit(item.balanceAfter)}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={b('tr')}>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.date}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{item.transitionType}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.amount)}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.balanceBefore)}</span>
      </div>
      <div className={b('td')}>
        <span className={b('td-el')}>{formatNumberWithLimit(item.balanceAfter)}</span>
      </div>
    </div>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
