import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';

import { formatNumberWithLimit } from 'shared/helpers/formatNumber';
import { userTypes } from 'features/users/data';
import SVG from 'components/SVG';

import './TableRow.scss';

const b = block('player-report-table-row-pagination');

const TableRow = ({ item, arrowOnClick }) => {
    const locale = useSelector(state => state.locale.locale, shallowEqual);

    return (
        <div className={b('tr')}>
            <div className={b('td')}>{item.id}</div>
            <div className={b('td')}>
                <div className={b('user-wrapper')}>
                    <div className={b('empty')}></div>
                    <div className={b('full-block')}>
                        <div className={b('user-block')}>
                            <SVG className={b('role-icon')} svgProps={{ svg: userTypes[item.role].icon }} />
                            <div className={b('user-info')}>
                                <span className={b('name-text')}>{item.email}</span>
                                <span className={b('role-text')}>
                  {locale[userTypes[item.role].type]}
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={b('td')}>{formatNumberWithLimit(item.amountIn)}</div>
            <div className={b('td')}>{formatNumberWithLimit(item.amountOut)}</div>
            <div className={b('td')}>{formatNumberWithLimit(item.total)}</div>
        </div>
    );
};

TableRow.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        amountIn: PropTypes.number.isRequired,
        amountOut: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
    }).isRequired,
};

export default TableRow;
